import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/* Constants */
import { callAPIConstants } from '../constants/callAPI-constants';
import { localStorageConstants } from '../constants/localStorage-constants';
import { URLConstants } from '../constants/routerLink-constants';
/* Services */
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  public accessPermissions: BehaviorSubject<any>
  public features_permission: BehaviorSubject<any>

  constructor(private commonService: CommonService, private localStorageService: LocalStorageService, public logoutService: LogoutService) {
    if (this.localStorageService.getToken(this.localStorageConstants.Permissions) !== 'undefined' || this.localStorageService.getToken(this.localStorageConstants.FeaturesPermission) !== 'undefined') {
      this.accessPermissions = new BehaviorSubject<any>(this.localStorageService.getToken(this.localStorageConstants.Permissions));
      this.features_permission = new BehaviorSubject<any>(this.localStorageService.getToken(this.localStorageConstants.FeaturesPermission));
    } else {
      this.logoutService.logout();
      this.localStorageService.removeToken(this.localStorageConstants.Permissions);
      this.localStorageService.removeToken(this.localStorageConstants.FeaturesPermission);
      this.commonService.router.navigate(['login']);
    }
  }

  /***************************************************************************
  @PURPOSE      : Get Permission
  @PARAMETERS   : N/A
  @RETURN       : Permission Observable
  ****************************************************************************/
  getPermissionData(): Observable<any> {
    return this.accessPermissions.asObservable();
  }
  /***************************************************************************
  @PURPOSE      : Set Permission
  @PARAMETERS   : details
  @RETURN       : N/A
  ****************************************************************************/
  setPermissionData(details) {
    this.localStorageService.setToken(this.localStorageConstants.Permissions, details.permissions);
    this.localStorageService.setToken(this.localStorageConstants.FeaturesPermission, details.features);
    this.accessPermissions = new BehaviorSubject<any>((details.permissions));
    this.features_permission = new BehaviorSubject<any>((details.features));
  }

  /***************************************************************************
   @PURPOSE      : Get Permissions List of Logged in User
   @PARAMETERS   : N/A
   @RETURN       : N/A
   ****************************************************************************/
  getPermissions() {
    this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.setPermissionData(success.data);
      }
    });
  }
  /***************************************************************************
  @PURPOSE      : Get Page wise Permissions
  @PARAMETERS   : page
  @RETURN       : access object
  ****************************************************************************/
  getPagePermission(page?): Object {
    let pageRes: any = {}
    let featuresRes: any = {}
    this.getPermissionData().subscribe(res => pageRes = res);
    this.getFeaturesPermission().subscribe(res => featuresRes = res);
    switch (page) {
      case 'my_account':
        return pageRes.my_account;
      case 'my_invite_links':
        pageRes.my_invite_links.user_view = pageRes.users.view;
        return pageRes.my_invite_links;
      case 'team_management':
        pageRes.team_management.role_view = pageRes.roles.view;
        return pageRes.team_management;
      case 'dashboard':
        return pageRes.dashboard;
      case 'courses':
        return pageRes.courses;
      case 'users':
        return pageRes.users;
      case 'user_groups':
        pageRes.user_groups.user_view = pageRes.users.view;
        return pageRes.user_groups;
      case 'products':
        return pageRes.products;
      case 'product_type':
        return pageRes.product_type;
      case 'resources':
        return pageRes.resources;
      case 'jobs':
        return pageRes.jobs;
      case 'news':
        return pageRes.news;
      case 'submodule_types':
        return pageRes.submodule_types;
      case 'reports':
        return pageRes.reports;
      case 'upload_media_costing':
        return pageRes.upload_media_costing;
      case 'roles':
        return pageRes.roles;
      /* Features Permission */
      default:
        return featuresRes;
    }
  }

  getFeaturesPermission() {
    return this.features_permission.asObservable();
  }

  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : N/A
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    tempData[index].isVisible = false;
    return tempData;
  }
}
