<aside class="sidebar">
    <div class="sidebar-inner-wrapper">
        <div class="sidebar-menu">
            <div class="team-sidebar" [hidden]="!accessPermissions?.team_management?.view">
                <div class="team-header">
                    <a href="javascript:;" (click)="teamMenuFlag = !teamMenuFlag" [ngClass]="{'active':teamMenuFlag}">
                        <span>Team</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                </div>
                <div class="team-result" [ngClass]="{'active':teamMenuFlag}">
                    <div class="team-name">
                        <img [src]="operatorTeamDetail?.image ? operatorTeamDetail?.image : 'assets/images/NoProfile.png'"
                            alt="Operator" width="24" height="24">
                        <span>{{operatorTeamDetail?.name}}</span>
                    </div>
                    <div class="team-list">
                        <ul>
                            <li *ngFor="let member of operatorTeamDetail?.team_members" [tooltip]="member.name">
                                <a href="javascript:;" (click)="teamMemberRoute(member.id)"
                                    [ngClass]="member.image == '' ? 'profile-name-box primary' : 'profile-img-box'">
                                    <img *ngIf="member.image; else elseInitial" [src]="member.image" alt="Team"
                                        width="32" height="32">
                                    <ng-template #elseInitial>
                                        {{member?.nameInitials}}
                                    </ng-template>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="add-team" [hidden]="!accessPermissions?.team_management?.add">
                        <a href="javascript:;" [routerLink]="[URLConstants.ADD_TEAM_MEMBER]"><em
                                class="bx bx-plus-circle"></em> Add Team</a>
                    </div>
                </div>
            </div>
            <ul class="sidebar-menu-list">
                <li class="menu-list-item" (click)="openCloseSubMenu('dashboardMenu')">
                    <a [routerLink]="[URLConstants.DASHBOARD]" class="menu-link"
                        [ngClass]="{'active': router.url.includes(URLConstants.DASHBOARD)}">
                        <em class="bx bx-bar-chart-square"></em>
                        <span class="menu-title">Dashboard</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item has-child-submenu" [hidden]="!accessPermissions?.courses?.view">
                    <a class="menu-link" (click)="openCloseSubMenu('coursesMenu')"
                        [ngClass]="{'active': router.url.includes(URLConstants.COURSES_LIST)}">
                        <em class="bx bx-news"></em>
                        <span class="menu-title">Courses</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show' : (activeMenuItem === 'coursesMenu' && !menuClose)}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.COURSES_LIST]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.COURSES_LIST)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Courses Directory</span>
                            </a>
                        </li>
                    </ul>
                </li> -->

                <li class="menu-list-item" (click)="openCloseSubMenu('coursesMenu')"
                    [hidden]="!accessPermissions?.courses?.view">
                    <a [routerLink]="[URLConstants.COURSES_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSES_LIST)}">
                        <em class="bx bx-news"></em>
                        <span class="menu-title">Courses</span>
                    </a>
                </li>

                <li class="menu-list-item" (click)="openCloseSubMenu('courseLinksMenu')"
                    [hidden]="!accessPermissions?.courses?.view">
                    <a [routerLink]="[URLConstants.COURSE_ASSIGN_LINKS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.COURSE_ASSIGN_LINKS)}">
                        <em class="bx bx-link-alt"></em>
                        <span class="menu-title">Course Assign Links</span>
                    </a>
                </li>

                <li class="menu-list-item has-child-submenu"
                    [ngClass]="{'active' : (activeMenuItem === 'userMenu' && !menuClose)}"
                    [hidden]="!accessPermissions?.users?.view && !accessPermissions?.user_groups?.view">
                    <a class="menu-link" (click)="openCloseSubMenu('userMenu')"
                        [ngClass]="{'active': router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USERS_DETAIL) || router.url.includes(URLConstants.CREATE_USER) || router.url.includes(URLConstants.EDIT_USER)|| router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-group"></em>
                        <span class="menu-title">Users</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list"
                        [ngClass]="{'show' : (activeMenuItem === 'userMenu' && !menuClose)}">
                        <li class="menu-list-item" [hidden]="!accessPermissions?.users?.view">
                            <a [routerLink]="[URLConstants.USERS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.USERS) || router.url.includes(URLConstants.USERS_DETAIL) || router.url.includes(URLConstants.CREATE_USER) || router.url.includes(URLConstants.EDIT_USER)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Manage Users</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!accessPermissions?.user_groups?.view">
                            <a [routerLink]="[URLConstants.GROUPS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.GROUPS) || router.url.includes(URLConstants.CREATE_GROUP) || router.url.includes(URLConstants.EDIT_GROUP)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Manage Groups</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="menu-list-item" [hidden]="!accessPermissions?.analytics?.view">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-bar-chart-alt"></em>
                        <span class="menu-title">Analytics</span>
                    </a>
                </li> -->
                <li class="menu-list-item" (click)="openCloseSubMenu('productMenu')"
                    [hidden]="!accessPermissions?.products?.view">
                    <a [routerLink]="[URLConstants.PRODUCT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PRODUCT)}">
                        <em class="bx bx-cube-alt"></em>
                        <span class="menu-title">Products</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('productTypesMenu')"
                    [hidden]="!accessPermissions?.product_type?.view">
                    <a [routerLink]="[URLConstants.PRODUCT_TYPES_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.PRODUCT_TYPES_LIST)}">
                        <em class="bx bx-cube-alt"></em>
                        <span class="menu-title">Product Types</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('resourceMenu')"
                    [hidden]="!accessPermissions?.resources?.view">
                    <a [routerLink]="[URLConstants.RESOURCES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.RESOURCES)}">
                        <em class="bx bx-spreadsheet"></em>
                        <span class="menu-title">Resources</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('jobsMenu')"
                    [hidden]="!accessPermissions?.jobs?.view">
                    <a [routerLink]="[URLConstants.JOBS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.JOBS)}">
                        <em class="bx bx-briefcase-alt"></em>
                        <span class="menu-title">Jobs</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item has-child-submenu" [ngClass]="{'submenu-open':subDirectoryMenu}" [hidden]="!accessPermissions?.product_directory?.view">
                    <a class="menu-link" (click)="openCloseSubMenu('subDirectoryMenu')"
                        [ngClass]="{'active':subDirectoryMenu || router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-data"></em>
                        <span class="menu-title">Directories</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list" [ngClass]="{'show':(activeMenuItem === 'subDirectoryMenu' && !menuClose)}">
                        <li class="menu-list-item" [hidden]="!accessPermissions?.product_directory?.view && !accessPermissions?.training_course_directory?.view">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Product Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Resource Directory</span>
                            </a>
                        </li>
                        <li class="menu-list-item" [hidden]="!accessPermissions?.training_course_directory?.view">
                            <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Course Directory</span>
                            </a>
                        </li>
                    </ul>
                </li> -->
                <li class="menu-list-item" (click)="openCloseSubMenu('newsMenu')"
                    [hidden]="!accessPermissions?.news?.view">
                    <a [routerLink]="[URLConstants.NEWS]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.NEWS)}">
                        <em class="bx bx-receipt"></em>
                        <span class="menu-title">News</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('submoduleTypesMenu')"
                    [hidden]="!accessPermissions?.submodule_types?.view">
                    <a [routerLink]="[URLConstants.SUBMODULE_TYPES]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.SUBMODULE_TYPES)}">
                        <em class="bx bxs-pen"></em>
                        <span class="menu-title">Submodule Types</span>
                    </a>
                </li>
                <!-- <li class="menu-list-item" [hidden]="!accessPermissions?.feedback?.view">
                    <a class="menu-link" [ngClass]="{'active':router.url.includes(URLConstants.GROUPS)}">
                        <em class="bx bx-happy-alt"></em>
                        <span class="menu-title">Feedback</span>
                    </a>
                </li> -->
                <li class="menu-list-item has-child-submenu"
                    [ngClass]="{'active' : (activeMenuItem === 'subReportsMenu' && !menuClose)}"
                    [hidden]="!accessPermissions?.reports?.view">
                    <a class="menu-link" (click)="openCloseSubMenu('subReportsMenu')"
                        [ngClass]="{'active': router.url.includes(URLConstants.PARTIAL_COMPLETION) || router.url.includes(URLConstants.NOT_STARTED_ANY_COURSE) || router.url.includes(URLConstants.NEVER_LOGGED_IN) || router.url.includes(URLConstants.LOW_COMPLETION_RATE)}">
                        <em class="bx bx-chart"></em>
                        <span class="menu-title">Reports</span>
                        <em class="bx bxs-chevron-right"></em>
                    </a>
                    <ul class="sidebar-menu-list sub-menu-list"
                        [ngClass]="{'show' : (activeMenuItem === 'subReportsMenu' && !menuClose)}">
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.PARTIAL_COMPLETION]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.PARTIAL_COMPLETION)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Partial Completition</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.NOT_STARTED_ANY_COURSE]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.NOT_STARTED_ANY_COURSE)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Not Started Any Courses</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.NEVER_LOGGED_IN]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.NEVER_LOGGED_IN)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Never Logged In</span>
                            </a>
                        </li>
                        <li class="menu-list-item">
                            <a [routerLink]="[URLConstants.LOW_COMPLETION_RATE]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.LOW_COMPLETION_RATE)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Low Completion Rate</span>
                            </a>
                        </li>
                        <li class="menu-list-item"
                            [hidden]="!(features_permission?.upload_media_costing && accessPermissions?.upload_media_costing?.view)">
                            <a [routerLink]="[URLConstants.COST_SAVED_FOR_JOBS]" class="menu-link"
                                [ngClass]="{'active':router.url.includes(URLConstants.COST_SAVED_FOR_JOBS)}">
                                <em class="bx bx-right-arrow-alt"></em>
                                <span class="menu-title">Cost Saved For Jobs</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('teamMenu')"
                    [hidden]="!accessPermissions?.team_management?.view">
                    <a [routerLink]="[URLConstants.TEAM_MEMBERS_LIST]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.TEAM_MEMBERS_LIST)}">
                        <em class='bx bx-user-plus'></em>
                        <span class="menu-title">Team Management</span>
                    </a>
                </li>
                <li class="menu-list-item"
                    [hidden]="!(features_permission?.upload_media_costing && accessPermissions?.upload_media_costing?.view)"
                    (click)="openCloseSubMenu('costingMenu')">
                    <a [routerLink]="[URLConstants.UPLOAD_MEDIA_COSTING]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.UPLOAD_MEDIA_COSTING)}">
                        <em class='bx bx-pound'></em>
                        <span class="menu-title">Upload Media Costing</span>
                    </a>
                </li>
                <li class="menu-list-item" (click)="openCloseSubMenu('accountMenu')"
                    [hidden]="!accessPermissions?.my_account?.view">
                    <a [routerLink]="[URLConstants.MY_ACCOUNT]" class="menu-link"
                        [ngClass]="{'active':router.url.includes(URLConstants.MY_ACCOUNT)}">
                        <em class="bx bx-cog"></em>
                        <span class="menu-title">My Account</span>
                    </a>
                </li>
            </ul>
            <!-- <div class="upgrade-course">
                <div class="arrow-up"><em class="bx bx-up-arrow-alt"></em></div>
                <h5>Upgrade to Pro for more resources</h5>
                <a href="javascript:;" class="upgrade-btn">Upgrade</a>
            </div> -->
        </div>
    </div>
</aside>