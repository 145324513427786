import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
/****************  constants *******************/
import { callAPIConstants } from '../constants/callAPI-constants';
import { localStorageConstants } from '../constants/localStorage-constants';
import { URLConstants } from '../constants/routerLink-constants';
/****************  service *******************/
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';
import { ShowErrorService } from './show-error.service';

@Injectable({
	providedIn: 'root',
})

export class LogoutService {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;
	localStorageConstants = localStorageConstants;

	constructor(public localstorage: LocalStorageService, private router: Router, private commonService: CommonService, private showErrorService: ShowErrorService) { }

	/*************************************************************
	@PURPOSE      : Logout
	@PARAMETERS   : NA
	@RETURN       : NA
	/*************************************************************/
	logout(isChangePwd = false) {
		let Id;
		let Pass;
		let remember;
		if (this.localstorage.getToken(this.localStorageConstants.Id) && this.localstorage.getToken(this.localStorageConstants.Pass)) {
			Id = this.localstorage.getToken(this.localStorageConstants.Id);
			Pass = this.localstorage.getToken(this.localStorageConstants.Pass);
			remember = true;
		}
		this.commonService.callApi(this.callAPIConstants.AdminLogout, '', 'post', false, false, false).then((success) => {
			if (!success.errors) {
				this.localstorage.clearToken();
				if (!isChangePwd)
					this.showErrorService.popToast('success', success.extra_meta.message);
				this.router.navigate([this.URLConstants.LOGIN]);
			} else {
				this.showErrorService.popToast('error', success.errors.message);
			}
			if (remember) {
				this.localstorage.setToken(this.localStorageConstants.Id, Id);
				this.localstorage.setToken(this.localStorageConstants.Pass, Pass);
			}
		})

	}
	/*************************************************************/
}
