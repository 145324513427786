import { environment } from "src/environments/environment";

export const localStorageConstants = {
    AccessToken: 'accessToken',
    Id: 'Id',
    Pass: 'Pass',
    OperatorId: 'OperatorId',
    FeaturesPermission: 'features_permission',
    Permissions: 'permissions',
    Name: 'name',
    Email: 'email',
    Photo: 'photo',
    ParentId: 'parentId',
    EnableUniqueId: 'enable_unique_id',
    EnableManagerEmail: 'enable_manager_email',
    EnableCustomContactInfo: 'enable_custom_contact_info',
    UniqueIdName: 'unique_id_name',
}
export const SECURE_LS = {
    ENCODING_TYPE: 'aes',
    STORAGE_KEY: '__secure__sb__ls__',
    ENCRYPTION_SECRET: environment.localStorageEncryptionSecret,
};
