import { isPlatformBrowser, } from '@angular/common';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { SECURE_LS } from '../constants/localStorage-constants';
import * as SecureLS from 'secure-ls';
@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {

	public platformId: any;
	private ls = new SecureLS({ encryptionSecret: SECURE_LS.ENCRYPTION_SECRET });
	constructor(injector: Injector) {
		this.platformId = injector.get(PLATFORM_ID);
	}

	/*************************************************************
	@PURPOSE      : We can use following function to use localstorage
	@PARAMETERS   : key, value
	@RETURN       : NA
	/*************************************************************/
	/***** Get storage  *****/
	getStorage() {
		if (isPlatformBrowser(this.platformId))
			return this.ls.get(SECURE_LS.STORAGE_KEY);
	}
	initialiseLS(keys) {
		if (isPlatformBrowser(this.platformId))
			this.ls.set(SECURE_LS.STORAGE_KEY, keys);
	}

	setToken(key, value) {
		if (isPlatformBrowser(this.platformId)) {
			let obj = this.getStorage();
			if (obj) {
				obj[key] = value;
				this.initialiseLS(obj);
			}
			else
				this.initialiseLS({ [key]: value });
		}
	}
	getToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			let lsItems = this.getStorage();
			return lsItems[key];
		}
	}
	removeToken(key) {
		if (isPlatformBrowser(this.platformId)) {
			let lsItems = this.getStorage();
			delete lsItems[key];
			this.initialiseLS(lsItems);
		}
	}
	clearToken() {
		if (isPlatformBrowser(this.platformId)) {
			this.ls.clear();
		}
	}
	/*************************************************************/
}
