import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/************ plugin  ************/
import { RecaptchaErrorParameters } from 'ng-recaptcha';
/************ environment  ************/
import { environment } from 'src/environments/environment';
/************ constants  ************/
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
import { localStorageConstants } from '../../common/constants/localStorage-constants';
/************ service  ************/
import { CommonService } from '../../common/service/common.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { ShowErrorService } from '../../common/service/show-error.service';
import { RolePermissionService } from '../../common/service/role-permission.service';
import { RestrictKeyPressService } from 'src/app/common/service/restrict-key-press.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  siteKey = environment.recaptcha.siteKey;
  public isSettingOpen = false;
  public submitted: boolean = false;
  passwordType: boolean;
  public user: any = {};

  constructor(public restrictKeyPressService: RestrictKeyPressService, private router: Router,
    private localStorageService: LocalStorageService, private commonService: CommonService, public showErrorService: ShowErrorService, private rolePermissionService: RolePermissionService) { }

  ngOnInit(): void {
    if (this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return;
    }
    if (this.localStorageService.getToken(this.localStorageConstants.Id) != null && this.localStorageService.getToken(this.localStorageConstants.Pass) != null) {
      this.user = {
        email: this.localStorageService.getToken(this.localStorageConstants.Id),
        password: this.localStorageService.getToken(this.localStorageConstants.Pass),
        keeplogin: true,
      };
    }
  }

  /*************************************************************
  @PURPOSE      : Operator Login
  @PARAMETERS   : form, user
  @RETURN       : NA
  /*************************************************************/
  onLogin(form, user) {
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.OperatorLogin, user, 'post', true, false).then((success) => {
        if (!success.errors) {
          if (user.keeplogin) {
            this.localStorageService.setToken(this.localStorageConstants.Id, user.email);
            this.localStorageService.setToken(this.localStorageConstants.Pass, user.password);
          } else {
            this.localStorageService.removeToken(this.localStorageConstants.Id);
            this.localStorageService.removeToken(this.localStorageConstants.Pass);
          }
          this.localStorageService.setToken(this.localStorageConstants.AccessToken, success.extra_meta.token);
          this.localStorageService.setToken(this.localStorageConstants.Name, success.data.name);
          this.localStorageService.setToken(this.localStorageConstants.Email, success.data.email);
          this.localStorageService.setToken(this.localStorageConstants.EnableUniqueId, success.data.enable_unique_id);
          this.localStorageService.setToken(this.localStorageConstants.EnableManagerEmail, success.data.enable_manager_email);
          this.localStorageService.setToken(this.localStorageConstants.EnableCustomContactInfo, success.data.enable_custom_contact_info);
          this.localStorageService.setToken(this.localStorageConstants.UniqueIdName, success.data.unique_id_name);
          this.localStorageService.setToken(this.localStorageConstants.ParentId, success.data.parent_id);
          this.localStorageService.setToken(this.localStorageConstants.OperatorId, success.data.id);
          this.localStorageService.setToken(this.localStorageConstants.Permissions, success.extra_meta.permissions);
          this.localStorageService.setToken(this.localStorageConstants.FeaturesPermission, success.extra_meta.features);
          if (this.localStorageService.getToken(this.localStorageConstants.Permissions)) {
            this.rolePermissionService.accessPermissions.next(success.extra_meta.permissions);
          }
          if (this.localStorageService.getToken(this.localStorageConstants.FeaturesPermission)) {
            this.rolePermissionService.features_permission.next(success.extra_meta.features);
          }
          if (success.data.photo != undefined) {
            this.localStorageService.setToken(this.localStorageConstants.Photo, success.data.photo);
          }
          this.showErrorService.popToast('success', 'Logged In successfully');
          this.router.navigate([this.URLConstants.DASHBOARD]);
        } else {
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }
  }

  /*************************************************************
  @PURPOSE      : callbacks for Recaptcha execution
  /*************************************************************/
  resolved(captchaResponse: string, form, user): void {
    if (captchaResponse) {
      user.captcha_response = captchaResponse;
      this.onLogin(form, user);
    }
  }

  onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  /*************************************************************/

}
