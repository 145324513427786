import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { ErrorMessages } from '../TS-files/errorMessages';
import { CommonService } from './common.service';


@Injectable({
	providedIn: 'root',
})
export class ShowErrorService {

	constructor(private errorMessage: ErrorMessages, private commonService: CommonService) { }

	/***************************************************************************
	@PURPOSE      : To show validation message
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	****************************************************************************/
	showError(field, errorObj?) {
		return this.errorMessage.getError(field, errorObj);
	}
	/****************************************************************************/

	/**************************************************************************
	@PURPOSE      : We can use following function to use Toaster Service.
	@PARAMETERS   : field_name, errorObj?
	@RETURN       : error message
	/**************************************************************************/
	popToast(type, title) {
		swal({
			position: 'center',
			type,
			text: title,
			showConfirmButton: false,
			timer: 2000,
			customClass: 'custom-toaster',
		});
	}
	showToast(type, title) {
		return swal({
			text: title,
			type,
			showCancelButton: false,
			confirmButtonText: 'OK',
			confirmButtonClass: 'btn btn-primary',
			cancelButtonClass: 'btn btn--outline-primary',
		});
	}
	confirmpopToast(type, title) {
		return swal({
			text: title,
			type,
			showCancelButton: true,
			confirmButtonText: 'Yes',
			confirmButtonClass: 'btn btn-primary',
			cancelButtonClass: 'btn btn--outline-primary',
		});
	}

	isSubmoduleNotifyUserChecked() {
		return new Promise(async (resolve, reject) => {
			this.confirmpopToast('warning', 'Are you sure, you want to send Push Notification to Users for the changes ?').then((result) => {
				resolve(result);
			});
		});

	}
	/*************************************************************************/

}
