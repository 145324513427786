import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
/***** constant ****/
import { localStorageConstants } from './common/constants/localStorage-constants';
/***** Services ****/
import { CommonService } from './common/service/common.service';
import { LocalStorageService } from './common/service/local-storage.service';
import { RolePermissionService } from './common/service/role-permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  localStorageConstants = localStorageConstants;
  title = 'SmartAwards Operator';
  constructor(private commonService: CommonService, private router: Router, private localStorageService: LocalStorageService, private rolePermissionService: RolePermissionService) {
    if (this.commonService.isBrowser()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
      });
    }
  }

  ngOnInit() {
    if (this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      this.rolePermissionService.getPermissions();
    }
  }
}
