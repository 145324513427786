import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
/***********  Constants ************/
import { localStorageConstants } from '../constants/localStorage-constants';
import { URLConstants } from '../constants/routerLink-constants';
/***********  Service ************/
import { LocalStorageService } from '../service/local-storage.service';
import { RolePermissionService } from '../service/role-permission.service';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate implements CanActivate {
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (!this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivate implements CanActivate {
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      return true;
    }
    else if (!this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      this.router.navigate([this.URLConstants.LOGIN]);
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
}

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanPublicActivate implements CanActivate {
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (!this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      return true;
    } else {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return false;
    }
  }
}

export interface IDeactivateComponent {
  canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

/****************************************************************************
@PURPOSE      : Dont allow to navigate pages without confirmation.
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/

@Injectable()
export class DeactivateGuard implements CanDeactivate<any> {
  component: Object;

  canDeactivate(component: IDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component.canExit ? component.canExit() : true;
  }
}

/****************************************************************************/
/****************************************************************************
@PURPOSE      : Dont allow to View any Module without permission (viewList)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanViewActivate {
  public permission: any;
  URLConstants = URLConstants;
  constructor(public getPermissionsService: RolePermissionService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot) {
    this.permission = this.getPermissionsService.getPagePermission(route.data.page);
    if (this.permission?.view) {
      return true;
    } else {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return false;
    }
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to View any Module without permission (viewList)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAddActivate {
  public permission: any;
  URLConstants = URLConstants;
  constructor(public getPermissionsService: RolePermissionService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot) {
    this.permission = this.getPermissionsService.getPagePermission(route.data.page);
    if (this.permission?.add) {
      return true;
    } else {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return false;
    }
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to View any Module without permission (viewList)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanEditActivate {
  public permission: any;
  URLConstants = URLConstants;
  constructor(public getPermissionsService: RolePermissionService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot) {
    this.permission = this.getPermissionsService.getPagePermission(route.data.page);
    if (this.permission.edit) {
      return true;
    } else {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return false;
    }
  }
}
/****************************************************************************/
