import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/* Constant */
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
/* Service */
import { CommonService } from '../../../common/service/common.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})

export class SidebarComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  localStorageConstants = localStorageConstants;

  public accessToken: string;
  public teamMenuFlag = false;
  activeMenuItem = '';
  public accessPermissions: any = {};
  public features_permission: any = {};

  constructor(public router: Router, private commonService: CommonService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.getOperatorTeamDetail();

    /* START: Role Permission */
    if (this.localStorageService.getToken(this.localStorageConstants.Permissions) !== 'undefined' || this.localStorageService.getToken(this.localStorageConstants.FeaturesPermission) !== 'undefined') {
      this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
        if (!success.errors) {
          this.accessPermissions = success.data?.permissions;
          this.features_permission = success.data?.features;
        }
      });
    }
    /* END: Role Permission */
  }

  ngDoCheck() {
    this.accessToken = this.localStorageService.getToken(this.localStorageConstants.AccessToken);
  }

  /*********************************************************************************
   @PURPOSE      : Open/ Close Menus
   @PARAMETERS   : menuName
   @RETURN       : N/A
   /*********************************************************************************/
  menuClose = false;
  openCloseSubMenu(menuName) {
    if (this.activeMenuItem == menuName) {
      this.menuClose = !this.menuClose;
    }
    this.activeMenuItem = menuName;
  }

  /*********************************************************************************
   @PURPOSE      : Get Operator Detail
   @PARAMETERS   : N/A
   @RETURN       : N/A
   /*********************************************************************************/
  operatorTeamDetail: any = {};
  getOperatorTeamDetail() {
    this.commonService.callApi(this.callAPIConstants.SidebarTeams, {}, 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.operatorTeamDetail = success.data;
        if (this.operatorTeamDetail)
          this.operatorTeamDetail.team_members.forEach(element => {
            let x = element.name.split(' ');
            if (x.length > 1)
              element.nameInitials = x[0].charAt(0) + x[1].charAt(0);
            else
              element.nameInitials = x[0].charAt(0);
            element.nameInitials = element.nameInitials.toUpperCase();
          });
      }
    });
  }

  teamMemberRoute(id: any){
    if(this.accessPermissions?.team_management?.edit){
      let url = URLConstants.EDIT_TEAM_MEMBER + id;
      this.router.navigate([url]);
    }
  }



}
