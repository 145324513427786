export const messages = {
    ERROR: {
        REQUIRED: {
            emailAddrs: 'Enter Email Address',
            Password: 'Enter Password',
            code: 'Enter Code',
            confirmPassword: 'Enter Confirm Password',
            newPassword: 'Enter New Password',
            oldPassword: 'Enter Old Password',
            /***** Team Member & Role*****/
            team_name: 'Enter Name',
            unique_id: 'Enter Unique ID',
            role: 'Select Role',
            roleName: 'Enter Role',
            /***** Courses *****/
            displayOrder: 'Select Vendor',
            /***** Submodules *****/
            submodule_name: 'Enter Submodule Name',
            info: 'Enter Info',
            subtitle: 'Enter Subtitle',
            button_text: 'Enter Button Text',
            duration: 'Enter Duration',
            unlock_datetime: 'Enter Unlock Date & Time',
            completion_percentage: 'Enter Submodule Completion',
            time_spent: 'Enter Time Spent',
            url_360: 'Enter URL',
            touch_count: 'Enter Touch Counts',
            condition: 'Select Condition',
            youtube_id: 'Enter Youtube URL',
            vimeo_id: 'Enter Vimeo URL',
            start_time: 'Enter Start Time',
            end_time: 'Enter End Time',
            sourceTitle: 'Enter Title',
            videoTitle: 'Enter Video Title',
            stepTitle: 'Enter Step Title',
            button1_text: 'Enter Button 1',
            button2_text: 'Enter Button 2',
            caption: 'Enter Caption',
            job_no_required: 'Enter No. of uploads required',
            job_topic: 'Enter Task Topic',
            quiz_duration: 'Select Duration of Quiz',
            custom_quiz_duration: 'Enter Custom Duration',
            passing_criteria: 'Enter Passing Criteria',
            failed_attempts: 'Enter No. of failed attempts',
            category_name: 'Enter Category',
            comment: 'Enter General Comment',
            ratings: 'Enter Ratings',
            answer: 'Enter Option',
            question: 'Enter Question',
            answer_explanation: 'Enter Answer Explanation',
            /***** Account *****/
            company_name: 'Enter Company Name',
            contact_no: 'Enter Contact No',
            website: 'Enter Website',
            address: 'Enter Address',
            latitude: 'Enter Latitude',
            longitude: 'Enter Longitude',
            unique_id_name: 'Enter Unique ID Name',
            domain_name: 'Enter Domain Name',
            /***** Courses *****/
            title: 'Enter Course Title',
            description: 'Enter Description',
            display_order: 'Choose Display Order',
            keywords: 'Enter Keywords',
            schedule_at: 'Pick Date & Time',
            moduleTitle: 'Enter Module Title',
            item_name: 'Enter Item Name',
            item_code: 'Enter Item Code',
            /***** Users Groups *****/
            manager_email: "Enter Manager's Email",
            groupName: 'Enter Group Name',
            email: 'Enter Email',
            /***** Resources *****/
            resource_name: 'Enter Resource Name',
            /***** Product *****/
            product_title: 'Enter Product Title',
            product_type: 'Choose Product Type',
            industry: 'Choose Indutry',
        },
        PATTERN: {
            manager_email: "Enter Valid Manager's Email",
            latitude: 'Enter Valid latitude',
            longitude: 'Enter Valid longitude',
            website: 'Website should start with https:// or http://',
            url_360: 'Enter URL starts with https:// or http:// or www.',
            youtube_id: 'Enter valid youtube URL which starts with http(s)://www. eg., https://www.youtube.com/watch?v=YE7VzlLtp-4',
            vimeo_id: 'Enter valid vimeo URL which starts with http(s):// eg., https://vimeo.com/23374724',
            email: 'Enter Valid Email',
            emailAddrs: 'Enter Valid Email Address',
            newPassword: 'Password must contain 8-20 digits with at least 1 uppercase, 1 lowercase and 1 special Character',
            link: 'url should contain https://',
            custom_quiz_duration: 'Custom Duration should be 1-240 mins(inclusive)',
            passing_criteria: 'Passing Criteria should be 1-100(inclusive)',
            failed_attempts: 'Failed attempts should be 1-10(inclusive)',
        },
        MINLENGTH: {
            contact_no: 'Contact number should be upto between 10 to 15 digits',
            code: 'Code must contain 6 digits',
            unique_id: 'Unique ID should have 6 digits',
            answer: 'Answer must be minimum 50 characters.',
            question: 'Question must be minimum 10 characters.',
            confirmPassword: 'Require atleast 8 characters',
            newPassword: 'Require atleast 8 characters',
        },
        MAXLENGTH: {
            job_no_required: 'No of uploads field will allow only 2 digits'
        }
    },
};
