import { Component, OnInit } from '@angular/core';
/******** plugin *********/
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-import-user',
  templateUrl: './error-import-user.component.html',
})
export class ErrorImportUserComponent implements OnInit {

  public unsuccessfulImport: any = {};
  constructor(public BsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
