import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/************ plugin  ************/
import { RecaptchaErrorParameters } from 'ng-recaptcha';
/************ environment  ************/
import { environment } from 'src/environments/environment';
/************ constants  ************/
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
/************ services  ************/
import { CommonService } from '../../common/service/common.service';
import { ShowErrorService } from '../../common/service/show-error.service';
import { LocalStorageService } from '../../common/service/local-storage.service';
import { RestrictKeyPressService } from 'src/app/common/service/restrict-key-press.service';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})

export class ResetPasswordComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;
  siteKey = environment.recaptcha.siteKey;
  public isSettingOpen: boolean = false;
  fieldTextType: boolean;
  passwordType: boolean;
  passwordType1: boolean;

  public user: any = {};
  public submitted: boolean = false;

  constructor(public restrictKeyPressService: RestrictKeyPressService, private localStorageService: LocalStorageService, private router: Router, private commonService: CommonService, public showErrorService: ShowErrorService) {
    this.user.token = this.router.parseUrl(this.router.url).queryParams['token'] || this.commonService.decrypt(window.sessionStorage.getItem('token'));
    this.user.email = this.router.parseUrl(this.router.url).queryParams['email'] || this.commonService.decrypt(window.sessionStorage.getItem('email'));
  }

  ngOnInit(): void {
    if (this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
      this.router.navigate([this.URLConstants.DASHBOARD]);
      return;
    }
  }

  /*************************************************************
  @PURPOSE      : Reset Password
  @PARAMETERS   : form, user
  @RETURN       : NA
  /*************************************************************/
  resetPassword(form, user) {
    this.submitted = true;
    if (form.valid) {
      let resetdata = {
        email: user.email,
        token: user.token,
        password: user.password,
        password_confirmation: user.confirmPassword,
        captcha_response: user.captcha_response
      }
      this.commonService.callApi(this.callAPIConstants.ResetPassword, resetdata, 'post', true, false).then((success) => {
        if (!success.errors) {
          window.sessionStorage.removeItem('email');
          window.sessionStorage.removeItem('token');
          this.showErrorService.popToast('success', success.extra_meta.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.errors.message);
        }
      });
    }
  }

  /*************************************************************
  @PURPOSE      : callbacks for Recaptcha execution
  /*************************************************************/
  resolved(captchaResponse: string, form, user): void {
    if (captchaResponse) {
      user.captcha_response = captchaResponse;
      this.resetPassword(form, user);
    }
  }
  onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
  /*************************************************************/

}


