export const callAPIConstants = {
    /***** Authorization APIs *****/
    OperatorLogin: 'login',
    VerifyOTP: 'verifyEmailToken',
    ForgotPassword: 'forgotPassword',
    ResetPassword: 'resetPassword',
    AdminLogout: 'logout',
    /***** Sidebar API *****/
    SidebarTeams: 'teamMembers/getLoginOperatorDetails',
    /***** Permission API *****/
    GetPermissionList: 'getPermissions',
    /***** Dashboard API *****/
    GetDashboardDetails: 'dashboard',
    /***** Account APIs *****/
    GetAccountDetail: 'myAccount',
    UpdateAccountDetails: 'myAccount',
    ChangePassword: 'changePassword',
    GetDomains: 'domainMapping',
    /***** Invite Link APIs *****/
    FirebaseURL: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyARLuF-M6ECnbSWXSURPL_5BsvGsOzzbbs',
    GetInviteLink: 'getInviteLink',
    StoreInviteLink: 'generateInviteLink',
    GetAllInviteLinks: 'getAllInviteLinks',
    GetInvitedUsersList: 'getAllAssignedUsers',
    DeleteInviteLinks: 'deleteInviteLinks',
    ChangeInviteLinkStatus: 'changeLinkStatus',

    /***** Filter APIs *****/
    GetFavoriteFilterAndColumnSettings: 'getTableSettings',
    SaveFavoriteFilter: 'saveFavouriteFilter',
    SaveColumnSettings: 'saveColumns',

    /***** Role APIs *****/
    RolesList: 'roles/getRoleList',
    AddRole: 'roles',
    GetRoleDetail: 'roles/',
    DeleteRole: 'roles/delete',
    RoleChangeStatus: 'roles/changeStatus',
    RoleGetModuleData: 'roles/roleModuleList',
    RoleGetaddedByColumnData: 'adminListInRoles',

    /***** Team Member APIs *****/
    TeamMembers: 'teamMembers/getListing',
    TeamChangeStatus: 'teamMembers/changeStatus',
    AddTeamMember: 'teamMembers',
    GetTeamMember: 'teamMembers/',

    /***** Team Member Groups APIs *****/
    TeamGroups: 'groups/getGroupList',
    ChangeTeamGroupStatus: 'groups/changeStatus',
    DeleteTeamGroup: 'groups/delete',
    AddTeamGroup: 'groups',
    GetTeamGroup: 'groups/',

    /***** Groups APIs *****/
    GetGroupsList: 'userGroups/getUserGroupList',
    ChangeGroupStatus: 'userGroups/changeStatus',
    DeleteUserGroup: 'userGroups/delete',
    AddUserGroup: 'userGroups',
    /***** Users APIs *****/
    ImportUsers: 'users/importFrontUsers',
    UserList: 'users/getFrontUsersList',
    UserChangeStatus: 'users/changeStatus',
    UserDeleteUsers: 'users/delete',
    AddUser: 'users',
    GetAllUserGroup: 'users/getAllUserGroup',
    GetAssignCourses: 'users/getTrainingCourseList',
    GetGroupDetailById: 'users/getUserGroupDetailById/',
    SetManagerDetail: 'users/getUserManagerDetailByField',
    GetUserDetail: 'users/getProfile/',
    GetAssignedCourses: "users/assignedCourses",
    ResendSignupInfo: "users/sendUserDetailsEmail",
    /***** Training Course APIs *****/
    GetCourse: 'trainingCourse/list',
    GetCourseById: 'trainingCourse/',
    GetOrderList: 'trainingCourse/getCourseDisplayOrders',
    FileUpload: 'uploadImage',
    MediaUpload: 'uploadMedia',
    AddNewCourse: 'trainingCourse',
    ChangeCourseStatus: 'trainingCourse/changeStatus',
    RequestCourse: 'trainingCourse/requestCourse',
    AddNewModule: 'trainingCourseModule',
    GetDefaultModuleImage: 'trainingCourseModule/getDefaultImages',
    GetModuleById: 'trainingCourseModule/',
    ChangeModuleStatus: 'trainingCourseModule/changeStatus',
    GetModuleList: 'trainingCourseModule/getAllCourseModules/',
    GetAllCoursesModulesList: 'trainingCourseModule/getAllCourseModulesList',
    CopyPasteModule: 'trainingCourseModule/copyPasteModule',
    DuplicateModule: 'trainingCourseModule/duplicateModule/',
    DuplicateCourse: 'trainingCourse/duplicateCourse/',
    AddModuleFromOtherCourses: 'trainingCourse/addModules',
    AddSubmoduleFromOtherCourses: 'trainingCourseModule/addSubModules',
    SortModules: "trainingCourseModule/sortModules",
    ViewAssignedUsers: 'trainingCourse/viewAssignedUsers',
    ResetCourse: 'trainingCourse/resetProgress',
    UnassignUsers: 'trainingCourse/unassignUser',
    PreselectCourse: 'trainingCourse/preSelect/',
    DownloadCSVs: 'trainingCourseSubmodule/quizMiniquizExport/',

    /***** Submodule APIs *****/
    GetSubmoduleTypes: 'trainingCourseSubmodule/getSubModuleTypes',
    GetAllSubmodulesList: 'trainingCourseSubmodule/getAllCourseSubModules/',
    AddSubmodule: 'trainingCourseSubmodule',
    GetSubmoduleById: 'trainingCourseSubmodule/',
    CopypasteSubmoduleById: 'trainingCourseSubmodule/copyPasteSubModule',
    DuplicateSubmoduleById: 'trainingCourseSubmodule/subModulesDuplicate/',
    ChangeSubmoduleStatus: 'trainingCourseSubmodule/changeStatus',
    GetSubModuleTypes: "trainingCourseSubmodule/getSubModuleTypes",
    SortSubmodules: "trainingCourseSubmodule/sortSubModules",

    /***** Course Assign Link APIs *****/
    GenerateCourseAssignLink: 'trainingCourse/storeAssignedLink',
    GetAllCourseAssignLinks: 'trainingCourse/getAllAssignedLink',
    GetCourseAssignedUsersList: 'trainingCourse/viewAssignedUsersThroughLink',
    DeleteCourseAssignLinks: 'trainingCourse/deleteAssignedLinks',
    UnassignUserLink: 'trainingCourse/unassignUsersLink',
    ChangeAssignUserStatus: 'trainingCourse/changeAssignedLinkStatus',

    /***** Question Bank APIs *****/
    QuestionCategories: 'trainingCourseSubmodule/quiz/categories/getListing',
    AddCategory: 'trainingCourseSubmodule/quiz/categories',
    GetCategoryById: 'trainingCourseSubmodule/quiz/categories/',
    GetCategoryQuestionsById: 'trainingCourseSubmodule/quiz/categories/listQuestions/',
    StoreCategoryQuestionsById: 'trainingCourseSubmodule/quiz/categories/storeQuestions',
    ChangeCategoryStatus: 'trainingCourseSubmodule/quiz/categories/changeStatus',
    ExportQuestionBank: 'trainingCourseSubmodule/quiz/questionBank/export/',
    ImportQuestionBank: 'trainingCourseSubmodule/quiz/questionBank/import',
    GetQuizCategories: 'trainingCourseSubmodule/getQuizCategories/',
    /***** View Response APIs *****/
    GetPocResponses: 'trainingCourseSubmodule/pocResponses/getListing',
    GetPocUserResponses: 'trainingCourseSubmodule/pocResponses/userResponse/getListing',
    ChangePocResponseStatus: 'trainingCourseSubmodule/pocResponses/region/changeStatus',
    AddEditPocResponseComment: 'trainingCourseSubmodule/pocResponses/region/comment',
    AddPocResponse: 'trainingCourseSubmodule/pocResponses/userResponse',
    GetViewResponseCourseFeedbackList: 'trainingCourseSubmodule/feedback/user/getListing',
    GetViewResponseCourseFeedbackDetail: 'trainingCourseSubmodule/feedback/',
    GetViewResponseJobList: 'jobs/getListing',
    GetViewResponseJobDetail: 'jobs',
    GetViewResponseQuizList: 'trainingCourseSubmodule/quiz/result/getListing',
    MarkAsPassed: 'trainingCourseSubmodule/quiz/result/markAsPass/',
    GetViewResponseQuizDetail: 'trainingCourseSubmodule/quiz/result/quizResults',
    /***** Resources APIs *****/
    GetResourceList: 'resources/getListing',
    GetResourceById: 'resources/',
    ChangeResourceStatus: 'resources/changeStatus',
    AddResource: 'resources',
    DeleteResource: 'resources/delete',
    /***** Products APIs *****/
    GetProductsList: 'products/getListing',
    ChangeProductStatus: 'products/changeStatus',
    DeleteProduct: 'products/delete',
    GetIndustries: 'products/getIndustries',
    GetProductTypes: 'products/getProductTypes',
    GetDisplayOrder: 'products/getDisplayOrder',
    GetProductById: 'products/',
    AddProduct: 'products',
    /***** News APIs *****/
    GetNewsList: 'news/getListing',
    GetNewsById: 'news/',
    AddNews: 'news',
    DeleteNews: 'news/delete',
    ChangeNewsStatus: 'news/changeStatus',
    /***** Jobs APIs *****/
    GetJobsList: 'jobs/getCourseJobList',
    DeleteJobs: 'jobs/delete',
    /***** Reports APIs *****/
    GetPartialCompletionReportsList: 'reports/partialCompletion',
    GetLowCompletionRateReportsList: 'reports/lowCompletionRate',
    GetCommonNeverReportsList: 'reports/commonNeverReport',
    SendReminderNotification: 'reports/sendReminderNotification',
    CostSavedForJobsReportsList: 'reports/costSavedForJobsReport',

    /***** Notifications APIs *****/
    NotificationList: 'notifications',
    GetTodayNotifications: 'webNotifications/todayWebNotifications',
    GetPreviousNotifications: 'webNotifications/previousWebNotifications',
    MarkAsRead: 'webNotifications/markasread',
    GetNotificationCount: 'webNotifications/notificationCount',
    /* Product Types APIs */
    GetProductTypesList: 'productTypes/getListing',
    ChangeProductTypeStatus: 'productTypes/changeStatus',
    DeleteProductType: 'productTypes/',
    GetProductTypeById: 'productTypes/',
    AddProductType: 'productTypes',
    ImportProductTypes: 'productTypes/importProductTypes',
    verifyRegisteredUser: 'users/verifyRegisteredUser',

    /* Operator Features APIs */
    GetCoursesForCost: 'costs/getUploadmediaSubmodules',
    SaveUploadMediaCosts: 'costs/saveCost',

    /* Submodule Types APIs */
    GetSubmoduleTypesList: 'getAllSubModuleTypes',
    GetSubmoduleTypeById: 'getSubmoduleType/',
    UpdateSubmoduleTypeById: 'updateSubmoduleType',
};

