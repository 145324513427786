export const environment = {
  production: false,
  environmentName: 'dev',
  port: 4049,
  recaptcha: {
    siteKey: '6LeTY2AjAAAAAOBeDlqoy5G7V3zM9zAuBHt4u8VP',
    secrectKey: '6LeTY2AjAAAAAFj70B3cotCeJdSzrohK5mz0VEdp',
  },
  localStorageEncryptionSecret: '!@#$&smartawards&@dev!',
  linkBaseUrl: 'https://vnops-api.devpress.net/v1/api',
  appleVerificationCode: '1661263167',
  apiUrl: 'https://vnops-api.devpress.net/v1/operator/'
};