import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
/*************** plugins *********************/
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/*************** constants *********************/
import { URLConstants } from '../constants/routerLink-constants';
import { localStorageConstants } from '../constants/localStorage-constants';
/*************** environment *********************/
import { environment } from 'src/environments/environment';
/*************** service *********************/
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})

export class CommonService {
  localStorageConstants = localStorageConstants;
  public API_URL = '';
  public platformId: any;
  public swal = swal;
  public notificationCount = new BehaviorSubject<any>(0);
  constructor(public router: Router, public injector: Injector, public Http: HttpClient, public localStorageService: LocalStorageService, public spinnerService: NgxSpinnerService, private sanitizer: DomSanitizer) {
    this.API_URL = environment.apiUrl;
    this.platformId = injector.get(PLATFORM_ID);
  }

  /*******************************************************************************************
  @PURPOSE      	: Call api.
  @Parameters 	  : {
                      url : <url of api>
                      data : <data object (JSON)>
                      method : String (get, post)
                      isForm (Optional) : Boolean - to call api with form data
                      isPublic (Optional) : Boolean - to call api without auth header
                    }
  @RETURN         : Data obtain for API
  /*****************************************************************************************/
  public showSkeletonLoader: boolean;
  callApi(url, data, method, isPublic?, isForm?, html?): Promise<any> {
    this.showSkeletonLoader = true;
    this.spinnerService.show();
    let headers;
    if (isPublic) {
      headers = new HttpHeaders({ 'content-Type': 'application/json' });
    } else if (html) {
      headers = new HttpHeaders({ 'content-Type': 'text/html', 'Authorization': `Bearer ${this.localStorageService.getToken(this.localStorageConstants.AccessToken)}` });

    } else {
      headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': `Bearer ${this.localStorageService.getToken(this.localStorageConstants.AccessToken)}` });
    }
    if (isForm) {
      headers = new HttpHeaders({ Authorization: `Bearer ${this.localStorageService.getToken(this.localStorageConstants.AccessToken)}` });
    }
    return new Promise((resolve, reject) => {
      if (method === 'post') {
        this.Http.post(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.resolveApiStatus('success', resolve, value);
        }, (error) => {
          this.resolveApiStatus('fail', resolve, error);
        });
      } else if (method === 'get') {
        this.Http.get(this.API_URL + url, { headers, params: data }).subscribe((value) => {
          this.resolveApiStatus('success', resolve, value);
        }, (error) => {
          this.resolveApiStatus('fail', resolve, error);
        });
      } else if (method === 'put') {
        this.Http.put(this.API_URL + url, data, { headers }).subscribe((value) => {
          this.resolveApiStatus('success', resolve, value);
        }, (error) => {
          this.resolveApiStatus('fail', resolve, error);
        });
      } else if (method === 'delete') {
        this.Http.delete(this.API_URL + url, { headers }).subscribe((value) => {
          this.resolveApiStatus('success', resolve, value);
        }, (error) => {
          this.resolveApiStatus('fail', resolve, error);
        });
      }
    });
  }
  callFirebaseURL(url, data): Promise<any> {
    this.showSkeletonLoader = true;
    this.spinnerService.show();
    let headers = new HttpHeaders({ 'content-Type': 'application/json' });
    return new Promise((resolve, reject) => {
      this.Http.post(url, data, { headers }).subscribe((value) => {
        this.resolveApiStatus('success', resolve, value);
      }, (error) => {
        this.resolveApiStatus('fail', resolve, error);
      });
    });
  }
  /* Common method for resolve promise */
  resolveApiStatus(type, resolve, value) {
    if (type == 'success') {
      resolve(value);
    } else if (type == 'fail') {
      resolve(value.error);
      this.error(value);
    }
    this.showSkeletonLoader = false;
    this.spinnerService.hide();
  }
  callApiObservable(url, data) {
    const headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': `Bearer ${this.localStorageService.getToken(this.localStorageConstants.AccessToken)}` });
    return this.Http.post(this.API_URL + url, data, { headers }).pipe(map((rsp) => {
      return rsp
    }));
  }

  /*****************************************************************************************
  @PURPOSE      : To Show session LogOut popup
  @PARAMETERS   : NA
  @RETURN       : NA
 /*****************************************************************************************/
  sessionLogOut() {
    window.localStorage.clear();
    swal({
      position: 'center',
      type: 'error',
      text: 'Session Timeout',
      showConfirmButton: false,
      timer: 1800,
      customClass: 'custom-toaster',
    });
    this.router.navigate([URLConstants.LOGIN]);
  }

  /*****************************************************************************************
  @PURPOSE      : To Show error on status 401, 422 or any other error
  @PARAMETERS   : NA
  @RETURN       : NA
  /*****************************************************************************************/
  error(error) {
    if (error.status === 401) {
      this.localStorageService.clearToken();
      this.router.navigate([URLConstants.LOGIN]);
    } else if (error.status === 404) {
      this.router.navigate([URLConstants.PAGE_NOT_FOUND]);
    } else if (error.message == 'Invalid token.') {
      swal({
        position: 'center',
        type: 'error',
        text: 'Invalid Token',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    } else if (error.status === 500) {
      swal({
        position: 'center',
        type: 'error',
        text: 'Internal Server Error',
        showConfirmButton: false,
        timer: 1800,
        customClass: 'custom-toaster',
      });
    }
  }

  /*****************************************************************************************
  @PURPOSE      : Used for encrypt the value
  @PARAMETERS   : data
  @RETURN       : Encrypted Data
  /*****************************************************************************************/
  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'secret key 123');
  }

  /*****************************************************************************************
  @PURPOSE      : Used for decryption of encrypted code
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  decrypt(data) {
    return JSON.parse(CryptoJS.AES.decrypt(data, 'secret key 123').toString(CryptoJS.enc.Utf8));
  }

  /*****************************************************************************************
  @PURPOSE      : Get / Set all submodules data on edit 
  @PARAMETERS   : data
  @RETURN       : Decrypted Data
  /*****************************************************************************************/
  public subModuledetails: BehaviorSubject<any> = new BehaviorSubject<any>({});
  getSubModuleDetails(): Observable<any> {
    return this.subModuledetails.asObservable();
  }
  setSubModuleDetails(details) {
    this.subModuledetails.next(details);
  }

  /*************************************************************
  @PURPOSE  : To check server or browser
  /*************************************************************/
  isBrowser() {
    if (isPlatformBrowser(this.platformId)) {
      return true;
    } else {
      return false;
    }
  }

  /*************************************************************
 @PURPOSE  : To set notification count
 /*************************************************************/
  setNotificationCount(count) {
    this.notificationCount.next(count)
  }

  /*****************************************************************************************
  @PURPOSE      : Sanitize URL
  @PARAMETERS   : url
  @RETURN       : Sanitized URL
  /*****************************************************************************************/
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  /*****************************************************************************************/

}
