<!--BEGIN: Subheader -->

<div class='page-header'>
  <div class='row'>
    <div class='col-md-12'>
      <div class="breadcrumb-blog justify-content-sm-between">
        <!-- BEGIN: Breadcrumbs -->
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="[URLConstants.DASHBOARD]"><em class='bx bx-home-circle'></em></a>
          </li>
          <li *ngFor="let breadcrumb of subHeaderData?.breadcrumbs" class="breadcrumb-item">
            <a *ngIf="breadcrumb.url; else elseBlock" href=""
              [routerLink]="breadcrumb.id ? [breadcrumb.url, breadcrumb.id] : [breadcrumb.url]">
              <em class="fa fa-home" *ngIf="breadcrumb?.localeKey === 'home'"></em>
              <span *ngIf="breadcrumb?.localeKey !== 'home'">{{breadcrumb?.localeKey}}</span>
            </a>
            <ng-template #elseBlock>
          <li class="active" aria-current="page" href="javascript:;">{{breadcrumb?.localeKey}}</li>
          </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- END: Subheader -->