import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
/******** constants *********/
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';
/******** services *********/
import { CommonService } from 'src/app/common/service/common.service';
/******** interface *********/
import { IBreadcrumbs } from 'src/app/common/interfaces/interfaces';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  public breadcrumbs: IBreadcrumbs[];
  public subHeaderData: any;
  callAPIConstants = callAPIConstants
  URLConstants = URLConstants;
  notificationCount: number;
  @ViewChild('loadingPreviousNotification') loadingPreviousNotification: ElementRef;
  @ViewChild('loadingTodayNotification') loadingTodayNotification: ElementRef;

  constructor(private commonService: CommonService, public router: Router) { }

  ngOnInit(): void {
    this.setBreadcrumbs()
    this.getTodayNotificationsList()
  }

  setBreadcrumbs() {
    this.breadcrumbs = [
      { localeKey: 'Notifications', url: null },
    ];
    this.subHeaderData = {
      breadcrumbs: this.breadcrumbs,
    };
  }

  /*********************************************************************************
  @PURPOSE      : Get  today web notifications list
  @PARAMETERS   : N/A
  @RETURN       : N/A
  /*********************************************************************************/
  public todayNotificationsList: any = [];
  getTodayNotificationsList() {
    this.todayNotificationCurrentPage = 1;
    this.commonService.callApi(this.callAPIConstants.GetTodayNotifications + '?page=' + this.todayNotificationCurrentPage, { today_page_limit: "18" }, 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.todayNotificationsList = success.data;
        this.todayNotificationCurrentPage = success.meta.current_page;
        this.todayNotificationLastPage = success.meta.last_page;
        if (this.todayNotificationsList.length > 0 && (this.todayNotificationCurrentPage < this.todayNotificationLastPage)) {
          this.showTodayList = true;
        } else {
          this.showTodayList = false;
          this.getPreviousNotificationList();
        }
      }
    });
  }

  /*********************************************************************************
  @PURPOSE      : Get previous web notifications list
  @PARAMETERS   : N/A
  @RETURN       : N/A
  /*********************************************************************************/
  public previousNotificationList: any = [];
  getPreviousNotificationList() {
    this.commonService.callApi(this.callAPIConstants.GetPreviousNotifications + '?page=' + this.previousNotificationCurrentPage, { previous_page_limit: "18" }, 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.previousNotificationList = success.data;
        this.previousNotificationCurrentPage = success.meta.current_page;
        this.previousNotificationLastPage = success.meta.last_page;
      }
    });
  }

  /*********************************************************************************
  @PURPOSE      : Mark as read the notification
  @PARAMETERS   : id(optional)
  @RETURN       : N/A
  /*********************************************************************************/
  markAsRead(id?) {
    let body: any = {};
    if (id) {
      body.id = id;
    }
    this.commonService.callApi(this.callAPIConstants.MarkAsRead, body, 'post', false, false, false).then((success) => {
      if (!success.errors) {
        if (!id) {
          this.getTodayNotificationsList();
          this.getNotificationCount()
        }
      }
    });
  }

  getNotificationCount() {
    this.commonService.callApi(this.callAPIConstants.GetNotificationCount, {}, 'get', false, false, false).then((success) => {
      if (!success.errors) {
        this.commonService.setNotificationCount(success.data);
      }
    });
  }

  gotoDetail(notification) {
    switch (notification.type) {
      case "Password":
        break;
      case "Feedback":
        this.markAsRead(notification.id)
        this.router.navigate([URLConstants.COURSE_FEEDBACK_RESPONSES, notification.training_course_name, notification.training_course_id, notification.module_name, notification.module_id, notification.submodule_name, notification.submodule_id, notification.type_id])
        break;
      case "Quiz":
        this.markAsRead(notification.id)
        this.router.navigate([URLConstants.QUIZ_RESPONSES, notification.training_course_name, notification.training_course_id, notification.module_name, notification.module_id, notification.submodule_name, notification.submodule_id])
        break;
      case "Job":
        this.markAsRead(notification.id)
        this.router.navigate([URLConstants.JOBS_RESPONSES, notification.training_course_name, notification.training_course_id, notification.module_name, notification.module_id, notification.submodule_name, notification.submodule_id, notification.type_id])
        break;
      case "Poc":
        this.markAsRead(notification.id)
        this.router.navigate([URLConstants.HOTSPOT_RESPONSES, notification.training_course_name, notification.training_course_id, notification.module_name, notification.module_id, notification.submodule_name, notification.submodule_id, notification.type_id])
        break;
    }

  }

  /*********************************************************
@PURPOSE: Get more data on scroll
*********************************************************/
  todayNotificationLastPage = 1;
  todayNotificationCurrentPage = 1;
  todayloadMore = true
  todayApiLoader = true;
  showTodayList: boolean = true;


  previousNotificationLastPage = 1;
  previousNotificationCurrentPage = 1;
  prevloadMore = true
  prevApiLoader = true;

  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.commonService.isBrowser()) {
      if (this.showTodayList) {
        let componentPosition: any
        if (this.loadingTodayNotification) {
          componentPosition = this.loadingTodayNotification.nativeElement.offsetTop - 1180;

          const scrollPosition = window.pageYOffset;
          const currentPage = this.todayNotificationCurrentPage;
          const lastPage = this.todayNotificationLastPage;
          if ((scrollPosition >= componentPosition) && this.todayloadMore) {
            if ((currentPage < lastPage) && this.todayloadMore) {
              this.todayloadMore = false;
              this.showTodayList = true
              this.todayloadMore = false
              this.todayNotificationCurrentPage = this.todayNotificationCurrentPage + 1;
              this.commonService.callApi(this.callAPIConstants.GetTodayNotifications + '?page=' + this.todayNotificationCurrentPage, { today_page_limit: "18" }, 'get', false, false, false).then((success) => {

                if (!success.errors) {
                  this.todayloadMore = true;
                  this.todayNotificationsList = this.todayNotificationsList.concat(success.data);
                  this.todayNotificationCurrentPage = success.meta.current_page;
                  this.todayNotificationLastPage = success.meta.last_page;
                }
              });
            } else {
              this.showTodayList = false
            }
          } else {
            this.todayloadMore = true;
          }
        }
      }
      else {
        let componentPosition: any
        if (this.loadingPreviousNotification) {
          componentPosition = this.loadingPreviousNotification.nativeElement.offsetTop - 1180;
          const scrollPosition = window.pageYOffset;
          const currentPage = this.previousNotificationCurrentPage;
          const lastPage = this.previousNotificationLastPage;
          if ((scrollPosition >= componentPosition) && this.prevloadMore) {
            if ((currentPage < lastPage) && this.prevloadMore) {
              this.prevloadMore = false;
              this.prevloadMore = false
              this.previousNotificationCurrentPage = this.previousNotificationCurrentPage + 1;
              this.commonService.callApi(this.callAPIConstants.GetPreviousNotifications + '?page=' + this.previousNotificationCurrentPage, { previous_page_limit: "18" }, 'get', false, false, false).then((success) => {

                if (!success.errors) {
                  this.prevloadMore = true
                  this.previousNotificationList = this.previousNotificationList.concat(success.data);
                  this.previousNotificationCurrentPage = success.meta.current_page;
                  this.previousNotificationLastPage = success.meta.last_page;
                }
              });
            }
          } else {
            this.prevloadMore = true;
          }
        }
      }
    }
  }
  /*********************************************************/


}
