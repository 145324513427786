<app-sub-header [subHeaderData]="subHeaderData"></app-sub-header>
<div class="main-content-area">
    <div class="page-title">
        <h1>Notifications</h1>
    </div>
    <div class="notification-section card ps ps--active-y">
        <div class="notification-row" *ngIf="todayNotificationsList.length > 0">
            <div class="notification-header">
                <h6>Today</h6>
                <a (click)="markAsRead()" href="javascript:;">Mark as Read</a>
            </div>
            <ng-container (scrolled)="onWindowScroll()" *ngFor="let TodayNotifications of todayNotificationsList">
                <div class="notification-item" [ngClass]="{'read-notification': !TodayNotifications.is_read}"
                    (click)="gotoDetail(TodayNotifications)">
                    <div class="icon-box" *ngIf="TodayNotifications.type==='Password'">
                        <em class="bx bx-key"></em>
                    </div>
                    <div class="icon-box" *ngIf="TodayNotifications.type==='Feedback'">
                        <em class="bx bx-star"></em>
                    </div>
                    <div class="icon-box" *ngIf="TodayNotifications.type==='Quiz'">
                        <em class="bx bx-x-circle"></em>
                    </div>
                    <div class="icon-box" *ngIf="TodayNotifications.type==='Job'">
                        <em class="bx bx-briefcase-alt"></em>
                    </div>
                    <div class="icon-box" *ngIf="TodayNotifications.type==='Poc'">
                        <em class="bx bx-image-alt"></em>
                    </div>
                    <!-- <div class="icon-box">
                        <em class="bx bx-calendar-x"></em>
                    </div> -->
                    <div class="notification-info">
                        <h6>{{TodayNotifications.message}}</h6>
                        <p>{{TodayNotifications.created_at| date:'d MMM hh:mm'}}</p>
                    </div>
                </div>
            </ng-container>
            <div #loadingTodayNotification></div>
        </div>
        <div class="notification-row" *ngIf="!showTodayList && previousNotificationList.length > 0">
            <div class="notification-header">
                <h6>Previous</h6>
                <a *ngIf="todayNotificationsList.length==0" (click)="markAsRead()" href="javascript:;">Mark as Read</a>
            </div>
            <ng-container (scrolled)="onWindowScroll()" *ngFor="let previousNotification of previousNotificationList">
                <div class="notification-item" [ngClass]="{'read-notification': !previousNotification.is_read}"
                    (click)="gotoDetail(previousNotification)">
                    <div class="icon-box" *ngIf="previousNotification.type==='Password'">
                        <em class="bx bx-key"></em>
                    </div>
                    <div class="icon-box" *ngIf="previousNotification.type==='Feedback'">
                        <em class="bx bx-star"></em>
                    </div>
                    <div class="icon-box" *ngIf="previousNotification.type==='Quiz'">
                        <em class="bx bx-x-circle"></em>
                    </div>
                    <div class="icon-box" *ngIf="previousNotification.type==='Job'">
                        <em class="bx bx-briefcase-alt"></em>
                    </div>
                    <div class="icon-box" *ngIf="previousNotification.type==='Poc'">
                        <em class="bx bx-image-alt"></em>
                    </div>
                    <div class="notification-info">
                        <h6>{{previousNotification.message}}</h6>
                        <p>{{previousNotification.created_at| date:'d MMM h:mm'}}</p>
                    </div>
                </div>
            </ng-container>
            <div #loadingPreviousNotification></div>
        </div>
        <div *ngIf="!todayNotificationsList.length && !previousNotificationList.length">
            No Notifications Available.
        </div>
    </div>
</div>