export const URLConstants = {
    /***** Unauthorized Pages *****/
    LOGIN: '/login',
    FORGOT_PASS: '/forgot-password',
    VERIFY_CODE: '/verify-code',
    RESET_PASS: '/reset-password',

    /***** Authorized Pages *****/
    MASTER: '/master',
    DASHBOARD: '/dashboard',
    NOTIFICATIONS: '/notifications',
    PAGE_NOT_FOUND: '/page-not-found',

    /***** Courses Pages *****/
    COURSES_LIST: '/courses',
    ADD_COURSE: '/courses/add',
    REQUEST_COURSE: '/courses/request-a-course',
    VIEW_COURSE: '/courses/view/',
    EDIT_COURSE: '/courses/edit/',
    ADD_MODULE: '/courses/add-module/',
    EDIT_MODULE: '/courses/edit-module/',
    VIEW_ASSIGNED_USERS: '/courses/assigned-users/',
    QUESTION_BANK: '/courses/question-bank/',
    EDIT_QUESTION_BANK: '/courses/question-bank/view/',
    /***** Course Assign Links Pages *****/
    COURSE_ASSIGN_LINKS: '/courses/assign-links',
    VIEW_COURSE_ASSIGN_USERS: '/courses/assign-links/view-assigned-users/',

    /***** Course Submodules Pages *****/
    SUBMODULE: '/courses/sub-module/',
    TITLE_SLIDE: '/courses/sub-module/title-slide',
    TOUR_360: '/courses/sub-module/360-tour',
    UPLOAD_VIDEO: '/courses/sub-module/upload-video',
    IMAGE_GALLERY: '/courses/sub-module/image-gallery',
    PRODUCT_LISTING: '/courses/sub-module/product-listing',
    HAPPY_UNHAPPY_FACT: '/courses/sub-module/happy-unhappy-fact',
    COURSE_FEEDBACK: '/courses/sub-module/course-feedback',
    VIDEO_GUIDE: '/courses/sub-module/video-guide',
    IMAGE_HOTSPOT: '/courses/sub-module/image-hotspot',
    CONFIRMATION: '/courses/sub-module/confirmation',
    PHOTO_HOTSPOT_UPLOADER: '/courses/sub-module/photo-hotspot-uploader',
    QUIZ: '/courses/sub-module/quiz',

    COURSE_FEEDBACK_RESPONSES: '/courses/view-response/course-feedback/',
    JOBS_RESPONSES: '/courses/view-response/jobs/',
    HOTSPOT_RESPONSES: '/courses/view-response/photo-hotspot-uploader/',
    QUIZ_RESPONSES: '/courses/view-response/quiz/',

    /***** Product Pages *****/
    PRODUCT: '/products',
    ADD_PRODUCT: '/products/add',
    EDIT_PRODUCT: '/products/edit/',
    EDIT_PRODUCT_BY_RESOURCE: '/products/edit-by-resource/',
    VIEW_PRODUCT: '/products/view/',

    /***** Resources Pages *****/
    RESOURCES: '/resource',
    ADD_RESOURCE: '/resource/add',
    EDIT_RESOURCE: '/resource/edit/',
    VIEW_RESOURCE: '/resource/view/',
    ADD_ASSIGN_REOSURCE: '/resource/add-for/',

    /***** News Pages *****/
    NEWS: '/news',
    ADD_NEWS: '/news/add',
    EDIT_NEWS: '/news/edit/',
    VIEW_NEWS: '/news/view/',
    EDIT_NEWS_BY_RESOURCE: '/news/edit-by-resource/',

    /***** Reports Pages *****/
    PARTIAL_COMPLETION: '/reports/partial-completion',
    NOT_STARTED_ANY_COURSE: '/reports/not-started-any-course',
    NEVER_LOGGED_IN: '/reports/never-logged-in',
    LOW_COMPLETION_RATE: '/reports/low-completion-rate',
    COST_SAVED_FOR_JOBS: '/reports/cost-saved-for-jobs',

    /***** Jobs Pages *****/
    JOBS: '/jobs',
    VIEW_JOB: '/jobs/view/',

    /***** Submodule Types Pages *****/
    SUBMODULE_TYPES: '/submodule-types',
    EDIT_SUBMODULE_TYPES: '/submodule-types/edit/',

    /***** My Account Pages *****/
    MY_ACCOUNT: '/my-account',
    CHANGE_PASS: '/my-account/change-password',
    NOTIFICATION_SETTING: '/my-account/notification-settings',
    VIEW_INVITED_USERS: '/my-account/view-invited-users/',

    /***** Users Pages *****/
    USERS: '/users',
    USERS_DETAIL: '/users/view-details/',
    ASSIGNED_COURSES: '/users/assigned-courses/',
    CREATE_USER: '/users/add',
    EDIT_USER: '/users/edit/',

    /***** Groups Pages *****/
    GROUPS: '/groups',
    CREATE_GROUP: '/groups/add',
    EDIT_GROUP: '/groups/edit/',
    BELONGED_USERS: '/groups/belonged-users/',
    ADD_BELONGED_USER: '/groups/belonged-users/add/',
    EDIT_BELONGED_USER: '/groups/belonged-users/edit/',

    /***** Roles Pages *****/
    MANAGE_ROLES: '/manage-roles',
    ADD_ROLE: '/manage-roles/add-role',
    EDIT_ROLE: '/manage-roles/edit-details/',

    /***** Team Members Pages *****/
    TEAM_MEMBERS_LIST: '/manage-team',
    ADD_TEAM_MEMBER: '/manage-team/add-member',
    EDIT_TEAM_MEMBER: '/manage-team/edit-member/',

    /***** Team Member Groups Pages *****/
    MANAGE_TEAM_GROUPS: '/manage-team-groups',
    ADD_TEAM_GROUP: '/manage-team-groups/add',
    EDIT_TEAM_GROUP: '/manage-team-groups/edit/',

    /***** Product Types Module*****/
    PRODUCT_TYPES_LIST: '/product-types',
    ADD_PRODUCT_TYPE: '/product-types/add',
    EDIT_PRODUCT_TYPE: '/product-types/edit/',

    /***** Operator Feature Pages *****/
    UPLOAD_MEDIA_COSTING: '/operator-features/upload-media-costing',

    /***** Setting Pages *****/
    SETTING_GENERAL: '/setting/setting-general',
    SETTING_SOCIALMEDIA: '/setting/setting-socialmedia',
    SETTING_PAYMENTGATEWAY: '/setting/setting-paymentgateway',
    SETTING_SMTPSMS: '/setting/setting-smtpsms',
    SETTING_EMAILNOTIFICATION: '/setting/setting-emailnotification',
};
