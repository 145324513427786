<section class="login-wrapper">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo-onboarding.svg" alt="Smart Awards">
                </a>
            </div>
            <div class="login-content">
                <h1 class="mb-2">Sign In</h1>
                <!-- <p>New to Our Product? <a href="javascript:;" class="text-link-btn text-primary">Create an Account</a></p> -->
            </div>
            <div class="login-form">
                <form #loginForm="ngForm" autocomplete="off" (ngSubmit)="captchaRef.execute()">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" name="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$"
                            id="email" placeholder="Enter Email Address" class="form-control"
                            (keypress)="restrictKeyPressService.allowCharacters($event)"
                            [ngClass]="((submitted || email.touched) && email.invalid)?'has-danger':''"
                            [(ngModel)]="user.email" required #email="ngModel">
                        <div *ngIf="(submitted || email.touched) && email.invalid" class="has-error">
                            {{showErrorService.showError('emailAddrs', email.errors)}} </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input [type]="passwordType ? 'text' : 'password'" name="password" id="password"
                            placeholder="Enter Password" class="form-control"
                            [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''"
                            [(ngModel)]="user.password" required #password="ngModel"
                            (keypress)="restrictKeyPressService.allowCharacters($event)" />
                        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field"
                            class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType,
                            'bx-show': passwordType
                          }" (click)="passwordType = !passwordType"></span>
                        <div *ngIf="(submitted || password.touched) && password.invalid" class="has-error">
                            {{showErrorService.showError('password', password.errors)}} </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-checkbox">
                            <label for="keeplogin" class="mb-0">
                                <input type="checkbox" name="keeplogin" id="keeplogin" [(ngModel)]="user.keeplogin">
                                <span></span>Keep me signed in </label>
                        </div>
                    </div>
                    <div class="login-btn">
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event, loginForm, user)"
                            (error)="onError($event)" errorMode="handled" size="invisible" [siteKey]="siteKey">
                        </re-captcha>
                        <button class="btn btn-primary btn-block" type="submit">Sign In</button>
                    </div>
                    <div class="forgot-password">
                        <a href="javascript:;" class="text-link-btn text-primary"
                            [routerLink]="[URLConstants.FORGOT_PASS]">Forgot your password?</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>