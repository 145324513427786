<div class="modal-header">
    <h4 class="modal-title"> Unsuccessful Import of Users</h4>
</div>
<div class="modal-body">
    <div *ngIf="unsuccessfulImport.email_exist?.length">
        <strong>{{unsuccessfulImport.email_exist_message}}</strong>
        <div *ngFor="let user of unsuccessfulImport.email_exist; let i = index">
            <p>{{i+1}}. Row No : {{ user.row }},
                Name : {{ user.name }},
                Email : {{ user.email }}
            </p>
        </div>
    </div>
    <div *ngIf="unsuccessfulImport.group_not_exist?.length">
        <strong>{{unsuccessfulImport.group_not_exist_message}}</strong>
        <div *ngFor="let user of unsuccessfulImport.group_not_exist; let i = index">
            <p>{{i+1}}. Row No : {{ user.row }},
                Name : {{ user.name }},
                Email : {{ user.email }}
                Group Name : {{ user.group_name }}
            </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="text-right">
        <button type="button" class="btn btn-primary btn-outline with-icon" (click)="BsModalRef.hide()"><em
                class="bx bx-x-circle"></em> Close</button>
    </div>
</div>