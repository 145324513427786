import { NgModule, NO_ERRORS_SCHEMA, SecurityContext } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
/********* pipe ***********/
import { DateFilterPipe, HtmlToTextPipe, NegToPosPipe, LimitTextPipe, SafeHtmlPipe } from '../common/service/common-pipe.pipe';
/********* Component ***********/
import { BnDatatableModule } from '../common/service/bn-datatable/bn-datatable.module';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ResponsiveDataTableComponent } from './components/responsive-data-table/responsive-data-table.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { AddEditCategoryComponent } from './modal/add-edit-category/add-edit-category.component';
import { ErrorImportUserComponent } from './modal/error-import-user/error-import-user.component';
import { TitleSlideComponent } from './preview-components/title-slide/title-slide.component'
import { Tour360Component } from './preview-components/tour360/tour360.component'
import { UploadVideoComponent } from './preview-components/upload-video/upload-video.component'
import { ImageGalleryComponent } from './preview-components/image-gallery/image-gallery.component'
import { ProductListingComponent } from './preview-components/product-listing/product-listing.component'
import { HappyUnhappyFactsComponent } from './preview-components/happy-unhappy-fact/happy-unhappy-fact.component'
import { CourseFeedbackComponent } from './preview-components/course-feedback/course-feedback.component'
import { VideoGuideComponent } from './preview-components/video-guide/video-guide.component'
import { ImageHotspotComponent } from './preview-components/image-hotspot/image-hotspot.component'
import { ConfirmationComponent } from './preview-components/confirmation/confirmation.component'
import { PhotoHotspotUploaderComponent } from './preview-components/phot-hotspot-uploader/phot-hotspot-uploader.component'
import { QuizComponent } from './preview-components/quiz/quiz.component'
import { JobsComponent } from './preview-components/jobs/jobs.component'
import { ConfirmationBoxesComponent } from './preview-components/confirmation-boxes/confirmation-boxes.component';
import { DocumentViewerComponent } from './preview-components/document-viewer/document-viewer.component'

/********* plugin ***********/
import { ImageCropperModule } from 'ngx-image-cropper';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MarkdownModule } from 'ngx-markdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { CountdownModule } from 'ngx-countdown';
import { NgxSpinnerModule } from "ngx-spinner";
import { QRCodeModule } from 'angularx-qrcode';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LineTruncationLibModule, LineTruncationDirective } from 'ngx-line-truncation';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { RatingModule } from 'ngx-bootstrap/rating';
import { HighchartsChartModule } from 'highcharts-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    AngularEditorModule,
    NgxSkeletonLoaderModule,
    RouterModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    ImageCropperModule,
    TooltipModule.forRoot(),
    DragDropModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    CountdownModule,
    BnDatatableModule,
    DataTablesModule,
    NgxSpinnerModule,
    QRCodeModule,
    NgxFileDropModule,
    LineTruncationLibModule,
    SlickCarouselModule,
    TabsModule.forRoot(),
    RatingModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    MarkdownModule.forRoot(
      { sanitize: SecurityContext.NONE }
    ),
    HighchartsChartModule,
    InfiniteScrollModule,
    ClipboardModule,
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    SubHeaderComponent,
    /**** pipe ****/
    DateFilterPipe,
    HtmlToTextPipe,
    NegToPosPipe,
    LimitTextPipe,
    SafeHtmlPipe,
    /***** submodule preview components ****/
    TitleSlideComponent,
    Tour360Component,
    UploadVideoComponent,
    ImageGalleryComponent,
    ProductListingComponent,
    HappyUnhappyFactsComponent,
    CourseFeedbackComponent,
    VideoGuideComponent,
    ImageHotspotComponent,
    ConfirmationComponent,
    PhotoHotspotUploaderComponent,
    QuizComponent,
    JobsComponent,
    ConfirmationBoxesComponent,
    DocumentViewerComponent,
    AddEditCategoryComponent,
    ErrorImportUserComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    /**** components ****/
    HeaderComponent,
    SidebarComponent,
    ResponsiveDataTableComponent,
    DragDropModule,
    SubHeaderComponent,
    /**** pipe ****/
    DateFilterPipe,
    HtmlToTextPipe,
    NegToPosPipe,
    LimitTextPipe,
    SafeHtmlPipe,
    /**** plugin ****/
    CommonModule,
    FormsModule,
    AngularEditorModule,
    ImageCropperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AccordionModule,
    BsDatepickerModule,
    TimepickerModule,
    TabsModule,
    QRCodeModule,
    PaginationModule,
    NgSelectModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    CountdownModule,
    BnDatatableModule,
    DataTablesModule,
    NgxSpinnerModule,
    NgxFileDropModule,
    ProgressbarModule,
    LineTruncationDirective,
    SlickCarouselModule,
    RatingModule,
    MarkdownModule,
    InfiniteScrollModule,
    HighchartsChartModule,
    ClipboardModule,
    /***** submodule preview components ****/
    TitleSlideComponent,
    Tour360Component,
    UploadVideoComponent,
    ImageGalleryComponent,
    ProductListingComponent,
    HappyUnhappyFactsComponent,
    CourseFeedbackComponent,
    VideoGuideComponent,
    ImageHotspotComponent,
    ConfirmationComponent,
    PhotoHotspotUploaderComponent,
    QuizComponent,
    JobsComponent,
    ConfirmationBoxesComponent,
    DocumentViewerComponent,
  ],
  entryComponents: [AddEditCategoryComponent, ErrorImportUserComponent]
})
export class SharedModule { }
