<section class="login-wrapper">
    <div class="login-body">
        <div class="login-container">
            <div class="logo-box">
                <a href="javascript:;">
                    <img src="assets/images/logo-onboarding.svg" alt="Smart Awards">
                </a>
            </div>
            <div class="login-content">
                <h1>Reset Password</h1>
                <p>Enter new password for your Smart Awards account</p>
            </div>
            <div class="login-form">
                <form #resetPasswordForm="ngForm" (ngSubmit)="captchaRef.execute()">
                    <div class="form-group">
                        <label for="newpassword">New password</label>
                        <input (keypress)="restrictKeyPressService.allowCharacters($event)" [type]="passwordType ? 'text' : 'password'" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$" name="password" id="newpassword" placeholder="Enter New password" class="form-control" [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''" required [(ngModel)]="user.password" #password="ngModel">
                        <span [tooltip]="passwordType ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType,
                            'bx-show': passwordType
                          }" (click)="passwordType = !passwordType"></span>
                        <div *ngIf="(submitted || password.touched) && password.invalid" class="has-error"> {{showErrorService.showError('newPassword', password.errors)}} </div>
                    </div>
                    <div class="form-group">
                        <label for="confirmpassword">Confirm password</label>
                        <input (keypress)="restrictKeyPressService.allowCharacters($event)" [type]="passwordType1 ? 'text' : 'password'" name="confirmPassword" id="confirmPassword" placeholder="Enter Confirm password" class="form-control" [ngClass]="((submitted || confirmPassword.touched) && confirmPassword.invalid)?'has-danger':''" required [(ngModel)]="user.confirmPassword" #confirmPassword="ngModel">
                        <span [tooltip]="passwordType1 ? 'Hide Password' : 'Show Password'" toggle="#password-field" class="bx bx-hide field-icon toggle-password" [ngClass]="{
                            'bx-bx-hide': !passwordType1,
                            'bx-show': passwordType1
                          }" (click)="passwordType1 = !passwordType1"></span>
                        <div *ngIf="(submitted || confirmPassword.touched) && confirmPassword.invalid" class="has-error"> {{showErrorService.showError('confirmPassword', confirmPassword.errors)}} </div>
                        <div class="has-error" *ngIf="((submitted || confirmPassword.touched || confirmPassword.dirty) && confirmPassword.valid && (user.confirmPassword != user.password))"> New Password & Confirm Password should be same </div>
                    </div>
                    <div class="login-btn">
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event, resetPasswordForm, user)" (error)="onError($event)" errorMode="handled" size="invisible" [siteKey]="siteKey">
                        </re-captcha>
                        <button class="btn btn-primary btn-block" type="submit">Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
