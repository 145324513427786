import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
/************  services *************/
import { LogoutService } from 'src/app/common/service/logout.service';
import { LocalStorageService } from '../../../common/service/local-storage.service';
import { CommonService } from '../../../common/service/common.service';
/************  constants *************/
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { RolePermissionService } from 'src/app/common/service/role-permission.service';
import { localStorageConstants } from 'src/app/common/constants/localStorage-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  localStorageConstants = localStorageConstants;

  public accessToken: string;
  public operatorName: string;
  public photo: string;
  notificationCount: number = 0;
  public accessPermissions: any = {};

  constructor(private localStorageService: LocalStorageService,
    private logoutService: LogoutService, private router: Router, private commonService: CommonService) {

    if (this.commonService.isBrowser()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.localStorageService.getToken(this.localStorageConstants.AccessToken)) {
            if (event.url !== '/login')
              this.commonService.callApi(this.callAPIConstants.GetNotificationCount, {}, 'get',).then((success) => {
                if (!success.errors) {
                  this.notificationCount = success.data
                }
              });
          }
        }
      });
    }
  }

  ngOnInit(): void {
    /* START: Role Permission */
    if (this.localStorageService.getToken(this.localStorageConstants.Permissions) !== 'undefined' || this.localStorageService.getToken(this.localStorageConstants.FeaturesPermission) !== 'undefined') {
      this.commonService.callApi(this.callAPIConstants.GetPermissionList, '', 'get', false, false, false).then((success) => {
        if (!success.errors) {
          this.accessPermissions = success.data?.permissions;
        }
      });
    }
    /* END: Role Permission */

    this.commonService.notificationCount.subscribe((data) => {
      this.notificationCount = data;
    })
    /*    const source = interval(5000);
        this.subscription = source.subscribe(val => this.testNotificationCalls());*/
  }
  /*testNotificationCalls(){
    this.commonService.callApi(this.callAPIConstants.GetNotificationCount, {}, 'get',).then((success) => {
      if (!success.errors) {
        this.notificationCount = success.data
      }
    });
  }*/
  @HostListener('window:scroll')
  onWindowScroll() {
    if (this.commonService.isBrowser()) {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        document.getElementById('header').classList.add('sticky');
      } else {
        document.getElementById('header').classList.remove('sticky');
      }
    }
  }

  logout() {
    this.logoutService.logout();
  }

  openSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input search-open');
    document.getElementsByTagName('body')[0].classList.add('body-search-open');
  }

  closeSearch() {
    Array.from(document.getElementsByClassName('search-input'))[0].setAttribute('class', 'search-input');
    document.getElementsByTagName('body')[0].classList.remove('body-search-open');
  }

  ngDoCheck(): void {
    this.operatorName = this.localStorageService.getToken(this.localStorageConstants.Name);
    this.photo = this.localStorageService.getToken(this.localStorageConstants.Photo);
  }
  /****************************************************************************/
}

