import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from '../constants/errorMessagesData';


@Injectable()
export class ErrorMessages {
  public MSG = (messages as any);

  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case 'emailAddrs': { message = required.emailAddrs } break;
          case 'email': { message = required.email; } break;
          case 'password': { message = required.Password; } break;
          case 'code': { message = required.code; } break;
          case 'confirmPassword': { message = required.confirmPassword; } break;
          case 'newPassword': { message = required.newPassword; } break;
          case 'oldPassword': { message = required.oldPassword; } break;
          /*************  Team Member & Role ******************/
          case 'team_name': { message = required.team_name; } break;
          case 'unique_id': { message = required.uniqueId; } break;
          case 'role': { message = required.role; } break;
          case 'roleName': { message = required.roleName; } break;
          /*************  Courses ******************/
          case 'title': { message = required.title } break;
          case 'description': { message = required.description } break;
          case 'display_order': { message = required.display_order } break;
          case 'schedule_at': { message = required.schedule_at } break;
          case 'moduleTitle': { message = required.moduleTitle } break;
          case 'displayOrder': { message = required.displayOrder; } break;
          /*************  Submodules ******************/
          case 'submodule_name': { message = required.submodule_name; } break;
          case 'info': { message = required.info; } break;
          case 'subtitle': { message = required.subtitle; } break;
          case 'button_text': { message = required.button_text; } break;
          case 'duration': { message = required.duration; } break;
          case 'unlock_datetime': { message = required.unlock_datetime; } break;
          case 'completion_percentage': { message = required.completion_percentage; } break;
          case 'time_spent': { message = required.time_spent; } break;
          case 'url_360': { message = required.url_360; } break;
          case 'condition': { message = required.condition; } break;
          case 'touch_count': { message = required.touch_count; } break;
          case 'item_name': { message = required.item_name } break;
          case 'item_code': { message = required.item_code; } break;
          case 'youtube_id': { message = required.youtube_id } break;
          case 'vimeo_id': { message = required.vimeo_id } break;
          case 'start_time': { message = required.start_time } break;
          case 'end_time': { message = required.end_time } break;
          case 'sourceTitle': { message = required.sourceTitle } break;
          case 'videoTitle': { message = required.videoTitle; } break;
          case 'stepTitle': { message = required.stepTitle; } break;
          case 'button1_text': { message = required.button1_text; } break;
          case 'button2_text': { message = required.button2_text; } break;
          case 'caption': { message = required.caption; } break;
          case 'job_no_required': { message = required.job_no_required; } break;
          case 'job_topic': { message = required.job_topic; } break;
          case 'quiz_duration': { message = required.quiz_duration; } break;
          case 'custom_quiz_duration': { message = required.custom_quiz_duration; } break;
          case 'passing_criteria': { message = required.passing_criteria; } break;
          case 'failed_attempts': { message = required.failed_attempts; } break;
          case 'category_name': { message = required.category_name; } break;
          case 'comment': { message = required.comment; } break;
          case 'ratings': { message = required.ratings; } break;
          case 'question': { message = required.question } break;
          case 'answer': { message = required.answer } break;
          case 'answer_explanation': { message = required.answer_explanation; } break;
          /*************  Account ******************/
          case 'company_name': { message = required.company_name; } break;
          case 'contact_no': { message = required.contact_no; } break;
          case 'website': { message = required.website; } break;
          case 'latitude': { message = required.latitude; } break;
          case 'longitude': { message = required.longitude; } break;
          case 'unique_id_name': { message = required.unique_id_name; } break;
          case 'domain_name': { message = required.domain_name; } break;
          /*************  User Groups ******************/
          case 'unique_id': { message = required.unique_id; } break;
          case 'manager_email': { message = required.manager_email; } break;
          case 'groupName': { message = required.groupName; } break;
          case 'email': { message = required.email; } break;
          /*************  Manage Resource ******************/
          case 'resource_name': { message = required.resource_name; } break;
          /*************  Manage Product ******************/
          case 'product_title': { message = required.product_title; } break;
          case 'product_type': { message = required.product_type; } break;
          case 'industry': { message = required.industry; } break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'emailAddrs': { message = pattern.emailAddrs; } break;
          case 'email': { message = pattern.email; } break;
          case 'password': { message = pattern.Password; } break;
          case 'latitude': { message = pattern.latitude; } break;
          case 'longitude': { message = pattern.longitude; } break;
          case 'url_360': { message = pattern.url_360; } break;
          case 'custom_quiz_duration': { message = pattern.custom_quiz_duration; } break;
          case 'passing_criteria': { message = pattern.passing_criteria; } break;
          case 'failed_attempts': { message = pattern.failed_attempts; } break;
          case 'website': { message = pattern.website; } break;
          case 'manager_email': { message = pattern.manager_email; } break;
          case 'completion_percentage': { message = pattern.completion_percentage; } break;
          case 'newPassword': { message = pattern.newPassword; } break;
          case 'confirmPassword': { message = pattern.confirmPassword; } break;
          case 'youtube_id': { message = pattern.youtube_id; } break;
          case 'vimeo_id': { message = pattern.vimeo_id; } break;

        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'code': { message = minlength.code; } break;
          case 'unique_id': { message = minlength.unique_id; } break;
          case 'answer': { message = minlength.answer; } break;
          case 'question': { message = minlength.question; } break;
          case 'description': { message = minlength.description; } break;
          case 'confirmPassword': { message = minlength.confirmPassword; } break;
          case 'newPassword': { message = minlength.newPassword; } break;
          case 'contact_no': { message = minlength.contact_no; } break;
        }
      } else if (error.maxlength) {
        const maxlength = this.MSG.ERROR.MAXLENGTH;
        switch (field) {
          case 'job_no_required': { message = maxlength.job_no_required; } break;

        }
      }
      return message;
    }
  }
  /*****************************************************************************************/
}
