<form #catForm="ngForm">
    <div class="modal-header">
        <h4 class="modal-title"> {{category.id ? 'Edit' : 'Add'}} Category</h4>
    </div>
    <div class="modal-body">
        <input type="text" (keypress)="restrictKeyPressService.allowCharacters($event)" class="form-control" name="name" #name="ngModel" [(ngModel)]="category.name" [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''" required>
        <div *ngIf="(submitted || name.touched) && name.invalid" class="has-error"> {{showErrorService.showError('category_name', name.errors)}} </div>
    </div>
    <div class="modal-footer">
        <div class="text-right">
            <button type="button" class="btn btn-primary btn-outline with-icon" (click)="BsModalRef.hide()"><em class="bx bx-x-circle"></em> Cancel</button>
            <button type="submit" class="btn btn-primary with-icon px-4" (click)="addUpdateCategory(catForm)"><em class='bx bx-save'></em> Save</button>
        </div>
    </div>
</form>
