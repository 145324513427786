import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
/**** Auth Guard ****/
import { CanAuthActivate, DeactivateGuard } from "./common/TS-files/auth.guard";
/**** plugin ****/
import { NgOtpInputModule } from 'ng-otp-input';
import { RecaptchaModule } from "ng-recaptcha";
/**** module ****/
import { SharedModule } from './shared/shared.module';
import { ForgotPasswordComponent } from "./public/forgot-password/forgot-password.component";
import { ConfirmEmailComponent } from "./public/confirm-email/confirm-email.component";
/**** Components ****/
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from "./public/login/login.component";
import { ResetPasswordComponent } from "./public/reset-password/reset-password.component";
import { NotificationsComponent } from './modules/notifications/notifications.component';
import { PageNotFoundComponent } from "./modules/page-not-found/page-not-found.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    pathMatch: "full",
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    pathMatch: "full",
  },
  {
    path: "verify-code",
    component: ConfirmEmailComponent,
    pathMatch: "full",
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [{ path: "notifications", component: NotificationsComponent, pathMatch: 'full' }]
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      {
        path: "dashboard",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (mod) => mod.DashboardModule
          )
      },
      {
        path: "courses",
        data: { page: 'courses' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/courses/courses.module").then(
            (mod) => mod.CoursesModule
          )
      },
      {
        path: "users",
        data: { page: 'users' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/users/users.module").then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: "groups",
        data: { page: 'user_groups' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/user-management/groups/groups.module").then(
            (mod) => mod.GroupsModule
          ),
      },
      {
        path: "products",
        data: { page: 'products' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/product/product.module").then(
            (mod) => mod.ProductModule
          ),
      },
      {
        path: "resource",
        data: { page: 'resources' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/resources/resources.module").then(
            (mod) => mod.ResourcesModule
          ),
      },
      {
        path: "news",
        data: { page: 'news' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/news/news.module").then(
            (mod) => mod.NewsModule
          ),
      },
      {
        path: "product-types",
        data: { page: 'product_type' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/product-type/product-type.module").then(
            (mod) => mod.ProductTypeModule
          ),
      },
      {
        path: "submodule-types",
        data: { page: 'submodule_types' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/submodule-types/submodule-types.module").then(
            (mod) => mod.SubmoduleTypesModule
          )
      },
      {
        path: "reports",
        data: { page: 'reports' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/reports/reports.module").then(
            (mod) => mod.ReportsModule
          ),
      },
      {
        path: "jobs",
        data: { page: 'jobs' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/jobs/jobs.module").then(
            (mod) => mod.JobsModule
          ),
      },
      {
        path: "my-account",
        data: { page: 'my_account' },
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/my-account/my-account.module").then(
            (mod) => mod.MyAccountModule
          ),
      },
      {
        path: 'manage-roles',
        data: { page: 'roles' },
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'manage-team',
        data: { page: 'team_management' },
        loadChildren: () => import('./modules/team-management/team-management.module')
          .then((m) => m.TeamManagementModule),
      },
      {
        path: 'manage-team-groups',
        data: { page: 'team_management' },
        loadChildren: () => import('./modules/manage-team-groups/manage-team-groups.module')
          .then((m) => m.ManageTeamGroupsModule),
      },
      {
        path: "operator-features",
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import("./modules/operator-features/operator-features.module").then(
            (mod) => mod.OperatorFeaturesModule
          )
      },
      {
        path: "page-not-found",
        component: PageNotFoundComponent,
        pathMatch: "full",
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    NotificationsComponent,
    PageNotFoundComponent,
    MainComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    RecaptchaModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule],
  providers: [CanAuthActivate, DeactivateGuard],
})
export class AppRoutingModule { }
